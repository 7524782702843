import React from "react";
import ChurchHero from "../images/Heros/Church-Hero.jpg";
import { Layout, Seo } from "../components";

const bodyText = [
	<p>The Church is a building project on NiteHawk's field</p>,
	<p>It is a memorial to the life of Brandon Burbridge</p>,
	<p>Click below if you'd like to contribute to Brandon Strong</p>,
];

export default function ChurchPage() {
	const pageData = {
		heroImg: ChurchHero,
		page: "",
		headerText: "Brandon Strong",
		bodyText,
		// Sort the link once we've got the donation platform up
		btnText: "Coming Soon",
		btnRoute: "/church",
		contents: "",
		defaultSport: "",
	};

	return (
		<>
			<Layout
				heroImg={pageData.heroImg}
				page={pageData.page}
				headerText={pageData.headerText}
				bodyText={pageData.bodyText}
				btnText={pageData.btnText}
				btnRoute={pageData.btnRoute}
				contents={pageData.contents}
				defaultSport={pageData.defaultSport}>
				<Seo title='Church' description='NiteHawk church page' />
			</Layout>
		</>
	);
}
